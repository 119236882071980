import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Deal, DealS, Filter } from "../services/DealS";
import { Outlet, useSearchParams } from "react-router-dom";
import { LocationContext } from "./LocationContext";
import Toast from "../atoms/Toast";
import _ from "lodash";

interface DealsContextProps {
  deals: Deal[];
  loading: boolean;
  filter: Filter;
  updateFilter: (patch: Partial<Filter>) => void;
  selectedDeal: Deal | undefined;
  selectDeal: (deal: Deal | undefined) => void;
  allDealsSeen: boolean;
  showMapPreview: boolean;
  setShowMapPreview: (show: boolean) => void;
  next: () => void;
}

export const DealsContext = createContext<DealsContextProps>(
  {} as DealsContextProps
);

export const DealsContextProvider = () => {
  const { addPartners } = useContext(LocationContext);
  const [searchParams] = useSearchParams();
  const [deals, setDeals] = useState<Deal[]>([]);
  const [filter, setFilter] = useState<Filter>({ categories: ["Restaurants"] });
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingPages, setLoadingPages] = useState(false);
  const [allDealsSeen, setAllDealsSeen] = useState<boolean>(false);
  const [selectedDeal, selectDeal] = useState<Deal>();
  const [showMapPreview, setShowMapPreview] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [pageOrder, setPageOrder] = useState<number[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);

  useEffect(() => {
    const partnerAlias = searchParams.get(DealS.FilterField.PARTNER);
    if (partnerAlias) {
      updateFilter({ partnerAlias });
    }
  }, [searchParams]);

  useEffect(() => {
    if (pageOrder.length > 0 && !loadingPages) {
      fetchDeals(pageNumber === 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageOrder, loadingPages]);

  useEffect(() => {
    setAllDealsSeen(false);
    fetchPagesCount();
    setPageNumber(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const updateFilter = (patch: Partial<Filter>) => {
    setFilter((prevFilter) => ({ ...prevFilter, ...patch }));
  };

  const fetchPagesCount = async () => {
    setLoadingPages(true);
    const pageResponse = await DealS.getDeals(0, filter);
    setLoadingPages(false);
    if (pageResponse) {
      const { totalPages } = pageResponse;
      if (totalPages === 0) {
        setAllDealsSeen(true);
      } else {
        const pages = Array.from(Array(totalPages).keys());
        setPageOrder(_.shuffle(pages));
      }
    }
  };

  const fetchDeals = async (clear = false) => {
    try {
      setLoading(true);
      const pageResponse = await DealS.getDeals(pageOrder[pageNumber], filter);
      const { content } = pageResponse;
      if (content) {
        addPartners(DealS.groupDealsByPartner(content));
        if (!deals || clear) {
          setDeals(content);
        } else {
          setDeals(deals.concat(content));
        }
      }
      if (pageOrder.length - 1 === pageNumber) {
        setAllDealsSeen(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const next = () => {
    if (pageNumber < pageOrder.length - 1) {
      setPageNumber(pageNumber + 1);
    }
  };

  const value = useMemo(
    () => ({
      deals,
      loading: loading || loadingPages,
      filter,
      updateFilter,
      selectedDeal,
      selectDeal,
      allDealsSeen,
      showMapPreview,
      setShowMapPreview,
      next,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deals, loading, filter, selectedDeal, allDealsSeen, showMapPreview]
  );

  return (
    <DealsContext.Provider value={value}>
      <Toast
        isOpen={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        severity={"warning"}
        message={
          "Bitte aktivieren Sie den Standort, wenn Sie die nächstgelegenen Angebote anzeigen möchten."
        }
      />
      <Outlet />
    </DealsContext.Provider>
  );
};
