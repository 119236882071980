import React, { useContext } from "react";
import Header from "../components/layout/Header";
import DealsArea from "../components/dealsArea/DealsArea";
import DealFilter from "../components/dealFilter/DealFilter";
import { ShapedGradientBox } from "../atoms/layout/Boxes";
import BackdropLoader from "../components/backdropLoader/BackdropLoader";
import NavigationIcon from "../components/NavigationIcon";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DealPopup from "../components/deal/DealPopup";
import DealsMapPreview from "../components/gmaps/DealsMapPreview";
import { DealsContext } from "../contexts/DealsContext";
import Footer from "../components/layout/Footer";
import DealCategoriesFilter from "../components/dealFilter/DealCategoriesFilter";

const DealsPage: React.FC = () => {
  const {
    loading,
    selectDeal,
    selectedDeal,
    showMapPreview,
    setShowMapPreview,
  } = useContext(DealsContext);

  return (
    <>
      <BackdropLoader isOpen={loading} />
      <DealPopup
        isOpen={selectedDeal !== undefined}
        onClose={() => selectDeal(undefined)}
        deal={selectedDeal}
        hideMap={showMapPreview}
      />
      <Header />
      <ShapedGradientBox style={{ position: "relative", zIndex: 10 }}>
        <DealFilter />
      </ShapedGradientBox>
      {showMapPreview ? (
        <>
          <DealsMapPreview />
          <NavigationIcon
            left={16}
            bottom={16}
            label="Show deals"
            icon={
              <ListAltIcon
                style={{ color: "black" }}
                onClick={() => setShowMapPreview(false)}
              />
            }
          />
        </>
      ) : (
        <DealsArea />
      )}
        <DealCategoriesFilter />
        <Footer />
    </>
  );
};

export default DealsPage;
