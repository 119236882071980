import { useState, useContext, useEffect, useRef } from "react";
import { PageTitle } from "../../atoms/Typography";
import TimeFramePopup from "../popups/TimeFramePopup";
import { Icon } from "./Styled";
import Panel from "../../atoms/layout/Panel";
import { RelativeBox } from "../../atoms/layout/Boxes";
import { RoundedInput } from "../../atoms/inputs/TextField";
import { Search } from "@mui/icons-material";
import { AppUserContext } from "../../contexts/AppUserContext";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import { Stack, Grid } from "@mui/material";
import { WindowContext } from "../../contexts/WindowContext";
import { DealsContext } from "../../contexts/DealsContext";
import { useDebouncedCallback } from "use-debounce";
import DealCategoriesFilter from "./DealCategoriesFilter";

const DealFilter = () => {
  const { isPhoneDevice } = useContext(WindowContext);
  const { isAuthenticated } = useContext(AppUserContext);
  const { updateFilter, filter } = useContext(DealsContext);
  const [pickingTimeframe, setPickingTimeframe] = useState(false);
  const { partnerAlias } = filter;
  const inputRef = useRef<HTMLInputElement>(null);

  const updateSeachQuery = useDebouncedCallback((val: string) => {
    updateFilter({ partnerAlias: val });
  }, 300);


  useEffect(() => {
    if (inputRef.current && partnerAlias && inputRef.current.value !== partnerAlias) {
      inputRef.current.value = partnerAlias;
    }
  }, [partnerAlias]);

  return (
    <Stack spacing={1} style={{ paddingBottom: 10 }}>
      <TimeFramePopup
        isOpen={pickingTimeframe}
        onCancel={() => setPickingTimeframe(false)}
      />
      <PageTitle small={isPhoneDevice}>DEINE DEALS</PageTitle>

      <Grid container justifyContent={"center"}>
        <Grid item xs={10} sm={8} md={6} lg={4} style={{ maxWidth: 600 }}>
          <RelativeBox>
            <Icon>
              <Search />
            </Icon>
            <RoundedInput
              ref={inputRef}
              placeholder={"Partner finden"}
              onChange={(e: any) => updateSeachQuery(e.target.value)}
            />
          </RelativeBox>
          <Panel smallMargin>
            <Stack direction={"row"} justifyContent={"center"} spacing={4}>
              <AccessTimeRoundedIcon onClick={() => setPickingTimeframe(true)} />
              {isAuthenticated && (
                  <>
                    {filter.favorites ? (
                        <FavoriteRoundedIcon
                            onClick={() => updateFilter({ favorites: false })}
                        />
                    ) : (
                        <FavoriteBorderRoundedIcon
                            onClick={() => updateFilter({ favorites: true })}
                        />
                    )}
                  </>
              )}
            </Stack>
          </Panel>
        </Grid>
      </Grid>
      <DealCategoriesFilter />
    </Stack>
  );
};

export default DealFilter;
