import { useState, useContext, useEffect, useRef } from "react";
import { Stack, Skeleton } from "@mui/material";
import { ToggleChip } from "../../atoms/shapes/ToggleChip";
import { DealS } from "../../services/DealS";
import { DealsContext } from "../../contexts/DealsContext";

const DealCategoriesFilter = () => {
  const { updateFilter, filter } = useContext(DealsContext);
  const [availableCategories, setCategories] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { categories, partnerAlias } = filter;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && partnerAlias && inputRef.current.value !== partnerAlias) {
      inputRef.current.value = partnerAlias;
    }
  }, [partnerAlias]);

  useEffect(() => {
    DealS.getCategories()
      .then((categories) => {
        if (categories) {
          setCategories(categories);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Stack spacing={1} style={{ paddingBottom: 10 }}>
      {loading ? (
        <Skeleton
          variant="text"
          height={40}
          width={350}
          style={{ marginLeft: 20 }}
        />
      ) : (
        <div className="center" style={{ marginLeft: 15, marginRight: 15 }}>
          <div className="scrollmenu">
            {availableCategories.map((category: string, index: number) => {
              return (
                <ToggleChip
                  key={category}
                  itemId={index}
                  label={category}
                  isSelected={categories && categories.includes(category)}
                  onSelect={() => updateFilter({ categories: [category] })}
                />
              );
            })}
          </div>
        </div>
      )}
    </Stack>
  );
};

export default DealCategoriesFilter;
