import React, { useState, useContext, useEffect } from "react";
import { Box, Dialog, DialogContent, Slide } from "@mui/material";
import { RelativeBox } from "../../atoms/layout/Boxes";
import DealPicturesGallery from "./DealPicturesGallery";
import { Deal, DealS } from "../../services/DealS";
import { LightText, RegularText } from "../../atoms/Typography";
import ConfirmPopup from "../popups/ConfirmPopup";
import Button from "../../atoms/inputs/Button";
import { DiscountBadgeAbsoluteBox } from "./styles";
import DealHeader from "./DealHeader";
import DealShare from "./DealShare";
import DiscountBadge from "../../atoms/shapes/DiscountBadge";
import { ContactContext } from "../../contexts/ContactContext";
import Panel from "../../atoms/layout/Panel";
import { PassS } from "../../services/PassS";
import { WindowContext } from "../../contexts/WindowContext";
import DealPopupCloseIcon from "./DealPopupCloseIcon";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { DateS } from "../../services/DateS";
import DealMapPreview from "../gmaps/DealMapPreview";

const DealPopup: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  deal?: Deal;
  hideMap?: boolean;
}> = ({ isOpen, onClose, deal, hideMap }) => {
  const { pass, refreshPass, setShowPass } = useContext(ContactContext);
  const { isPhoneDevice } = useContext(WindowContext);
  const navigate: NavigateFunction = useNavigate();
  const [confirming, setConfirming] = useState<boolean>(false);
  const [isDealAccessible, setAccessiblity] = useState(true);
  const isDealAvailableToday = DateS.isDealAvailable(deal);

  useEffect(() => {
    if (deal && pass) {
      DealS.verifyDealAccessibility(deal.id).then(setAccessiblity);
    }
  }, [deal, pass]);

  const createDealTicket = () => {
    setConfirming(false);
    if (deal) {
      PassS.createTicket(deal.id).then((success: boolean) => {
        if (success) {
          refreshPass();
          onClose();
          setShowPass(true);
        }
      });
    }
  };

  const handleConfirm = () => {
    if (!pass) {
      navigate("/register");
    } else {
      setConfirming(true);
    }
  };

  if (!deal) {
    return null;
  }

  return (
    <Dialog
      fullScreen={isPhoneDevice}
      open={isOpen}
      TransitionComponent={Slide}
      onClose={onClose}
      style={{
        margin: isPhoneDevice ? 10 : 0,
      }}
      PaperProps={{
        style: {
          borderRadius: 25,
          overflowX: "hidden",
          zIndex: 10,
          backgroundImage:
            "linear-gradient(to bottom, #ebebf4, #ffffff, #f0f0f8, #f0f0f8, #ebebf4)",
        },
      }}
    >
      <DialogContent
        style={{
          width: isPhoneDevice ? "100%" : "475px",
          padding: 0,
        }}
      >
        <ConfirmPopup
          isOpen={confirming}
          onConfirm={createDealTicket}
          onCancel={() => setConfirming(false)}
        />
        <RelativeBox>
          <DealPicturesGallery dealId={deal.id} partnerId={deal.partnerId} />
          <DealPopupCloseIcon onClose={onClose} color={"#f5f5f5"} />
          <DiscountBadgeAbsoluteBox>
            <DiscountBadge discount={deal.saveEuro} size={80} />
          </DiscountBadgeAbsoluteBox>
        </RelativeBox>
        <Box mt={4} ml={6} mb={4} mr={6}>
          <DealHeader {...deal} />
        </Box>
        <DealShare deal={deal} />
        <Box mt={4} ml={6} mr={6} mb={4}>
          <RegularText>{deal.description}</RegularText>
        </Box>
        {!hideMap && <DealMapPreview deal={deal} />}
      </DialogContent>
      {(!pass || (pass && !pass.ticket && !pass.validFrom)) && (
        <Panel>
          <Button
            label={pass ? "JETZT EINLÖSEN" : "10 Tage kostenlos testen"}
            onClick={handleConfirm}
            width={"250px"}
            disabled={pass && (!isDealAvailableToday || !isDealAccessible)}
          />
          {!isDealAccessible && (
            <Box mt={2} ml={6} mr={6} mb={2} maxWidth={325}>
              <LightText>
                Das Nutzungslimit für dieses Angebot wurde überschritten.
              </LightText>
            </Box>
          )}
          {!isDealAvailableToday && isDealAccessible && (
            <Box mt={2} ml={6} mr={6} mb={2} maxWidth={325}>
              <LightText>
                Deal ist heute nicht verfügbar. Bitte versuchen Sie es an einem
                anderen Tag oder wählen Sie ein anderes Angebot.
              </LightText>
            </Box>
          )}
        </Panel>
      )}
    </Dialog>
  );
};

export default DealPopup;
