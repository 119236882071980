import React, { useState, useEffect, useContext } from "react";
import validator from "validator";
import Header from "../components/layout/Header";
import { SecondaryText, PageTitle } from "../atoms/Typography";
import Panel from "../atoms/layout/Panel";
import { TextField } from "../atoms/inputs/TextField";
import Button from "../atoms/inputs/Button";
import CheckBox from "../atoms/inputs/CheckBox";
import { AuthS } from "../services/AuthS";
import {
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import PasswordChecker from "../components/passwordChecker/PasswordChecker";
import Alert from "@mui/material/Alert";
import { AlertS, AlertType } from "../services/AlertS";
import PageContainer from "../atoms/layout/PageContainer";
import BackdropLoader from "../components/backdropLoader/BackdropLoader";
import { WindowContext } from "../contexts/WindowContext";

const RegisterPage: React.FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const { isPhoneDevice } = useContext(WindowContext);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertType | null>();
  const [isPasswordValid, setValidPassword] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => {
    const event: string | null = searchParams.get("event");
    if (event) {
      const alert: AlertType = AlertS.Alert[event];
      if (alert) {
        setAlert(alert);
      }
    }
  }, []);

  const handleRegister = () => {
    if (validateForm()) {
      setLoading(true);
      AuthS.registerApplicationUser({ email, password })
        .then((alert: AlertType) => {
          if (alert === AlertS.Alert.SUCCESS) {
            AuthS.login({
              username: email,
              password,
            }).then((alert: AlertType) => {
              if (alert === AlertS.Alert.SUCCESS) {
                navigate("/purchase-pass");
              } else {
                setAlert(alert);
              }
            });
          } else {
            setAlert(alert);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setShowErrors(true);
    }
  };

  const validateForm = (): boolean => {
    return validator.isEmail(email) && termsAccepted && isPasswordValid;
  };

  return (
    <>
      <BackdropLoader isOpen={loading} />
      <Header />
      <PageContainer>
        <Panel>
          <PageTitle small={isPhoneDevice}>10 TAGE TESTEN</PageTitle>
        </Panel>
        {alert && (
          <Alert variant="filled" severity={alert.severity}>
            {alert.message}
          </Alert>
        )}
        <Panel>
          <TextField
            value={email}
            onChange={setEmail}
            label={"E-mail"}
            type="email"
            error={!validator.isEmail(email) && showErrors}
            errorMessage={"Email Adresse ist nicht gültig."}
          />
        </Panel>
        <Panel>
          <TextField
            value={password}
            onChange={setPassword}
            label={"Passwort"}
            type="password"
            error={!isPasswordValid && showErrors}
            errorMessage={
              "Das Passwort entspricht nicht den Richtlinien bedingungen."
            }
          />
        </Panel>
        <PasswordChecker
          password={password}
          showErrors={showErrors}
          setValidPassword={setValidPassword}
        />
        <br />
        <Panel>
          <CheckBox
            checked={termsAccepted}
            setChecked={setTermsAccepted}
            text={
              <>
                Hiermit bestätige ich, die
                <a target={"_blank"} href={"/legal/agb.pdf"}>
                  {" "}
                  <u>AGBs</u>{" "}
                </a>
                und
                <a target={"_blank"} href={"/data-privacy"}>
                  {" "}
                  <u>Datenschutzerklärung</u>{" "}
                </a>
                zur Kenntnis genommen zu haben.
              </>
            }
            error={!termsAccepted && showErrors}
            errorMessage={"Sie müssen die AGBs und die Datenschutzerklärung akzeptieren"}
            width={"100%"}
          />
        </Panel>
        <br />
        <Panel>
          <Button label={"JETZT 10 TAGE KOSTENLOS TESTEN"} onClick={handleRegister} />
        </Panel>
        <Panel>
          <SecondaryText>
            <a href="/login">Login</a>
          </SecondaryText>
        </Panel>
      </PageContainer>
    </>
  );
};

export default RegisterPage;
